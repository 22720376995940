import React from "react"
import { CardClass, dateClass, versionClass, notesClass } from "./card.css"

function Card({ date, notes, version }) {
  return (
    <div className="CardClass">
      <div className="dateClass">{date}</div>
      <div className="versionClass">v {version}</div>
      <div
        className="notesClass"
        dangerouslySetInnerHTML={{
          __html: `${notes}`,
        }}
      />
    </div>
  )
}

export default Card
